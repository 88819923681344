import { ref } from '@vue/reactivity'
import { projectFirestore } from '../firebase/config';

export const branches = ref([])
export const suppliers = ref([])
export const branche_unsub = ref(null)
export const supplier_unsub = ref(null)
export const get_branches_from_db=async()=>{
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Branches').get()
    return docs.docs.map(doc=>doc.data().name)
}
export const get_full_branches_from_db=async()=>{
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Branches').get()
    return docs.docs.map(doc => ({...doc.data(),marked:false})).filter(doc => !doc.name.includes('פארם') && !doc.name.includes('לוגיסטיקה'))
}

export const get_hr_companies_from_db=async()=>{
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Hr_Companies').get()
    return docs.docs.map(doc=>doc.data().name)
}
export const get_suppliers_from_db=async()=>{
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Suppliers').get()
    return docs.docs.map(doc=>doc.data().name)
}
export const get_branches_from_db_RT=async()=>{
    branche_unsub.value = projectFirestore.collection('Shivuk').doc('Shivuk').collection('Branches').onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
                branches.value.push(change.doc.data().name)
            }
            if (change.type === "removed") {
                const index = branches.value.findIndex(branche=>branche==change.doc.id)
                if(index!=-1){
                    branches.value.splice(index,1) 
                }
            }
        })
    })
}
export const get_suppliers_from_db_RT=async()=>{
    supplier_unsub.value = projectFirestore.collection('Shivuk').doc('Shivuk').collection('Suppliers').onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
                console.log(change.doc.data().name);
                suppliers.value.push(change.doc.data().name)
            }
            if (change.type === "removed") {
                const index = suppliers.value.findIndex(supplier=>supplier==change.doc.id)
                if(index!=-1){
                    suppliers.value.splice(index,1) 
                }
            }
        })
    })
}
export const add_new_branche_to_db=async(new_branche)=>{
    await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Branches').
    doc(new_branche).set({
        name:new_branche
    })   
}
export const add_new_hr_company_to_db=async(new_company)=>{
    await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Hr_Companies').
    doc(new_company).set({
        name:new_company
    })
    
}
export const add_new_supplier_to_db=async(new_supplier)=>{
    await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Suppliers').
    doc(new_supplier).set({
        name:new_supplier
    })   
}
export const add_new_equipment_to_db = async(new_equipment)=>{
    const doc =  projectFirestore.collection('Shivuk').doc('Shivuk').collection('Equipment').doc()
    await doc.set({
        name:new_equipment,
        uid:doc.id,
    })
    return {
        name:new_equipment,
        uid:doc.id,
    }   
}
export const get_equipments_from_db=async()=>{
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk')
    .collection('Equipment').orderBy('name','asc').get()
    return docs.docs.map(doc=>doc.data())
}
export const get_errors_from_db = async() => {
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk')
    .collection('ErrorsServices').orderBy('name','asc').get()
    return docs.docs.map(doc=>doc.data())
}
export const add_new_error_to_db = async(new_service_error) => {
    const doc =  projectFirestore.collection('Shivuk').doc('Shivuk').collection('ErrorsServices').doc()
    await doc.set({
        name:new_service_error,
        uid:doc.id,
    })
    return {
        name:new_service_error,
        uid:doc.id,
    }   
}