import { ref } from "vue"
import { onBeforeRouteLeave } from "vue-router"

export const handle_back_button_on_browser = ()=>{
    const state = ref(null)
    const back_btn = ref(false)

    onBeforeRouteLeave((to,from,next)=>{
        setTimeout(() => {
            if(state.value && back_btn.value){
                state.value = null
                back_btn.value = false
                console.log('changed_to:',back_btn.value);
                next(false)
            }else{
                next()
            }
        }, 500);
    })

    window.addEventListener('popstate', () => {
        back_btn.value = true
        setTimeout(() => {
             back_btn.value = false
        }, 500);
    })

    return {state}
}